
// CATEGORIA
var sistemas = $('#categoria__interna');
if (sistemas.length>0){
    $('select[name=\'country_id\']').on('change', function() {
        $.ajax({
            url: 'index.php?route=product/product/country&country_id=30',
            dataType: 'json',
            beforeSend: function() {
                $('select[name=\'country_id\']').after(' <i class="fa fa-circle-o-notch fa-spin"></i>');
            },
            complete: function() {
                $('.fa-spin').remove();
            },
            success: function(json) {
                if (json['postcode_required'] == '1') {
                    $('input[name=\'postcode\']').parent().parent().addClass('required');
                } else {
                    $('input[name=\'postcode\']').parent().parent().removeClass('required');
                }

                html = '<option value=""> --- Selecione --- </option>';

                if (json['zone'] && json['zone'] != '') {
                    for (i = 0; i < json['zone'].length; i++) {
                        html += '<option value="' + json['zone'][i]['zone_id'] + '"';

                        html += '>' + json['zone'][i]['name'] + '</option>';
                    }
                } else {
                    html += '<option value="0" selected="selected"><?php echo $text_none; ?></option>';
                }

                $('select[name=\'zone_id\']').html(html);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $('select[name=\'country_id\']').trigger('change');

    $(function(){

        $('input[name="postcode"]').mouseout(function(){
            var cep = $.trim($('input[name="postcode"]').val().replace('-', ''));

            $('#simular').removeAttr('disabled');

            $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(dados){
                if(!("erro" in dados)){

                    $('select[name="country_id"]').find('option[value="30"]').attr('selected', true);

                    $.post('index.php?route=product/product/estado_autocompletar&estado=' + unescape(dados.uf), function(zone_id){
                        $.ajax({
                            url: 'index.php?route=product/product/country&country_id=30',
                            dataType: 'json',
                            beforeSend: function() {

                                $('select[name=\'country_id\']').after(' <i class="fa fa-circle-o-notch fa-spin"></i>');

                            },
                            complete: function() {
                                $('.fa-spin').remove();
                            },
                            success: function(json) {
                                if (json['postcode_required'] == '1') {
                                    $('input[name=\'postcode\']').parent().parent().addClass('required');

                                } else {
                                    $('input[name=\'postcode\']').parent().parent().removeClass('required');
                                }

                                var html = '<option value=""> --- Selecione --- </option>';

                                if (json['zone'] != '') {
                                    for (i = 0; i < json['zone'].length; i++) {
                                        html += '<option value="' + json['zone'][i]['zone_id'] + '"';

                                        if (json['zone'][i]['zone_id'] == zone_id) {
                                            html += ' selected="selected"';
                                        }

                                        html += '>' + json['zone'][i]['name'] + '</option>';
                                    }
                                } else {
                                    html += '<option value="0" selected="selected"><?php echo $text_none; ?></option>';

                                }

                                $('select[name=\'zone_id\']').html(html);
                            }
                        });
                    });
                }
            });
        });
    });
    $(function(){
        $('select[name="zone_id"]').parent().parent().hide();
        $('select[name="country_id"]').parent().parent().hide();
    });

    jQuery('input[name=\'postcode\']').keydown(function(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            jQuery('#simular').trigger('click');
            return false;
        }
    });

    $('#simular').on('click',function(){
        $.ajax({
            url:'index.php?route=product/product/quoteProduct',
            type:'post',
            data:$('#options input[type=\'text\'],#options input[type=\'hidden\'],#options input[type=\'radio\']:checked,#options input[type=\'checkbox\']:checked,#options select,#options textarea'),
            dataType:'json',
            beforeSend:function(){
                $('#simular').attr('disabled', true).text('Aguarde...');
            },
            complete:function(){
            },
            success:function(json){
                $('.alert, .text-danger').remove();
                $.ajax({
                    url:'index.php?route=product/product/quote',
                    type:'post',
                    data:'country_id='+$('select[name=\'country_id\']').val()+'&zone_id='+$('select[name=\'zone_id\']').val()+'&postcode='+encodeURIComponent($('input[name=\'postcode\']').val()),
                    dataType:'json',
                    complete: function(){
                        $('#simular').attr('disabled', false).text('Calcular');
                    },
                    success:function(json){
                        if(json['error']){
                            if(json['error']['warning']){
                                $('#notification').html('<div class="alert alert-danger"><i class="fa fa-exclamation-circle"></i> '+json['error']['warning']+'<button type="button" class="close" data-dismiss="alert">&times;</button></div>');
                                $('html, body').animate({ scrollTop: 0 }, 500);
                            }
                            if(json['error']['postcode']){
                                alert("Atenção: Verifique o CEP Digitado!")
                            }
                        }
                        if(json['shipping_method']){
                            $('#modal-shipping').remove();
                            html='<div id="modal-shipping" class="modal">';
                            html+='  <div class="modal-dialog">';
                            html+='    <div style="' +
                                'position: relative;\n' +
                                '    display: -webkit-box;\n' +
                                '    display: -ms-flexbox;\n' +
                                '    display: flex;\n' +
                                '    -webkit-box-orient: vertical;\n' +
                                '    -webkit-box-direction: normal;\n' +
                                '    -ms-flex-direction: column;\n' +
                                '    flex-direction: column;\n' +
                                '    width: 100%;\n' +
                                '    pointer-events: auto;\n' +
                                '    background-color: #fff;\n' +
                                '    background-clip: padding-box;\n' +
                                '    border: 1px solid rgba(0,0,0,.2);\n' +
                                '    border-radius: 0.3rem;\n' +
                                '    outline: 0;" class="modal-content">';
                            html+='      <div class="modal-header">';
                            html+='        <h3 class="modal-title"><strong><i style="font-size:22px;"class="fa fa-truck"> </i> </strong>Estimar frete</h3>';
                            html+='      </div>';
                            html+='      <div class="modal-body">';
                            for(i in json['shipping_method']){
                                html+='<p><strong>'+json['shipping_method'][i]['title']+'</strong></p>';
                                if(!json['shipping_method'][i]['error']){
                                    for(j in json['shipping_method'][i]['quote']){
                                        html+='<div class="radio">';
                                        html+='  <label>';
                                        html+=json['shipping_method'][i]['quote'][j]['title']+' - '+json['shipping_method'][i]['quote'][j]['text']+'</label></div>'
                                    }
                                }  else {
                                    html+='<div class="alert alert-danger">'+json['shipping_method'][i]['error']+'</div>'}}
                            html+='      </div>';
                            html+='      <div class="modal-footer">';
                            html+='        <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar <i class="fa fa-close"></i></button>';
                            html+='      </div>';
                            html+='    </div>';
                            html+='  </div>';
                            html+='</div> ';
                            $('body').append(html);
                            $('#modal-shipping').modal('show');
                            $('#simular').prop('disabled',true);
                            $('input[name=\'shipping_method\']').on('change',function(){
                                $('#button-shipping').prop('disabled',false);
                            });
                        }
                    }
                })},
            error:function(xhr,ajaxOptions,thrownError){
                $('#simular').attr('disabled', false).text('Calcular');
                alert(thrownError+"\r\n"+xhr.statusText+"\r\n"+xhr.responseText);}})});
    jQuery('#form-avaliar').on('submit', function(event) {
        var product_id = $(this).data('product');
        event.preventDefault();
        jQuery.ajax({
            url: 'index.php?route=product/product/write&product_id='+product_id,
            type: 'post',
            dataType: 'json',
            data: 'name=' + encodeURIComponent(jQuery('#form-avaliar input[name=\'name\']').val()) + '&text=' + encodeURIComponent(jQuery('#form-avaliar textarea[name=\'text\']').val()) + '&rating=' + encodeURIComponent(jQuery('#form-avaliar input[name=\'rating\']:checked').val()),
            beforeSend: function() {
                jQuery('#form-avaliar .alert').remove();
                jQuery('#form-avaliar button[type="submit"]').attr('disabled', true).before('<div class="wait"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</div>');
            },
            complete: function() {
                jQuery('#botao-avaliar').attr('disabled', false);
                jQuery('.wait').remove();
            },
            success: function(data) {
                if (data['error']) {
                    jQuery('#form-avaliar').prepend('<div class="alert alert-danger"><span class="fa-stack fa-lg"><i class="fa fa-exclamation-triangle fa-stack-2x active"></i></span><span> ' + data['error'] + '</span></div>');
                }

                if (data['success']) {
                    jQuery('#form-avaliar').prepend('<div class="alert alert-success"><span class="fa-stack fa-lg"><i class="fa fa-thumbs-up fa-stack-2x active"></i></span><span> ' + data['success'] + '</span></div>');
                    jQuery('input[name=\'name\']').val(null);
                    jQuery('textarea[name=\'text\']').val(null);
                }
            }
        });
    });
    // jQuery('#button-cart').on('click', function() {
    //     console.log('Clicou');
    //     jQuery('.obrigatoriedades').addClass('hidden');
    //     jQuery.ajax({
    //         url: 'index.php?route=checkout/cart/add',
    //         type: 'post',
    //         data: jQuery("#options input[type='text'],#options input[type='number'], #options input[type='hidden'], #options input[type='radio']:checked, #options input[type='checkbox']:checked, #options select, #options textarea, #input-cor, #input-tamanho, .quantity"),
    //         dataType: 'json',
    //         beforeSend: function(r) {
    //         },
    //         success: function(json) {
    //             if (json['error']) {
    //                 if (json['error']['option']) {
    //                     for (i in json['error']['option']) {
    //                         jQuery('#options').before('<div class="alert alert-danger obrigatoriedades mgt-20">' + json['error']['option'][i] + '</div>');
    //                     }
    //                 }
    //             }
    //             jQuery('#carrinho').html(json['total']);
    //
    //             if (json['success']) {
    //                 location='index.php?route=checkout/cart';
    //             }
    //         }
    //     });
    //     return false;
    // });
}