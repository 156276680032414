$('form[data-newsletter="true"]').on('submit', function(event) {
	const $url = 'index.php?route=extension/module/newsletter';

	event.preventDefault();
	var form = this, botao = $(form).find("input[type='submit'], input[type='image'], button[type='submit']");

	function _campos() {
		return $(form).find(`
			input[type="text"],
			input[type="email"], 
			input[type="hidden"],
			input[type="radio"]:checked,
			input[type="checkbox"]:checked  
		`);
	}


	jQuery.ajax({
		url: $url,
		type: 'post',
		dataType: 'json',
		data: _campos(),
		beforeSend: function() {
			botao.attr('disabled', true);
		},
		complete: function() {
			botao.attr('disabled', false);
		},
		success: function(data) {
			if (data['erro']) {
				Swal.fire(
					'Ops..',
					''+data['erro']+'',
					'error'
				)
			}
			if (data['sucesso']) {

				Swal.fire(
					'Sucesso!',
					''+data['sucesso']+'',
					'success'
				)


				form.email.value = "";
				if (form.nome) {
					form.nome.value = "";
				}
			}
		}
	});
	return false;
});

$(document).ready(function() {
	try {
		$('#popup__abertura').modal('show');
	}
	catch(err) {
	}
});


$(document).ready(function() {
	jQuery.ajax({
		url: 'index.php?route=common/home/session_popup',
		success: function(html) {

		}
	});
});
