import verificaSeScriptExiste from "./verifica-se-script-existe.js";

const inputTels = $('input[type="tel"]');

// Verificando se o script do mask input já existe para não carregá-lo novamente
verificaSeScriptExiste({
    prop      : 'maskinput',
    callback  : createTelMask,
    scriptUrl : "js/jquery.mask.min.js"
});

function createTelMask() {
    const maskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    options = {
        onKeyPress: function(val, e, field, options) {
            field.mask(maskBehavior.apply({}, arguments), options);
        }
    };
    
    inputTels.mask(maskBehavior, options);

    $('#input-custom-field1').mask('000.000.000-00', {clearIfNotMatch: true}).attr('inputmode', 'numeric');
    $('#input-custom-field2').mask('00/00/0000', {clearIfNotMatch: true}).attr('inputmode', 'numeric');
    $('#input-custom-field5').mask('00.000.000/0000-00', {clearIfNotMatch: true}).attr('inputmode', 'numeric');
}
